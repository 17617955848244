export const regions = [
  { id: 'badenWuerttemberg', name: 'Baden-Württemberg' },
  { id: 'bavaria', name: 'Bayern' },
  { id: 'berlin', name: 'Berlin' },
  { id: 'brandenburg', name: 'Brandenburg' },
  { id: 'bremen', name: 'Bremen' },
  { id: 'hamburg', name: 'Hamburg' },
  { id: 'hessen', name: 'Hessen' },
  { id: 'lowerSaxony', name: 'Niedersachsen' },
  { id: 'mecklenburgVorpommern', name: 'Mecklenburg-Vorpommern' },
  { id: 'northRhineWestphalia', name: 'Nordrhein-Westfalen' },
  { id: 'rhinelandPalatinate', name: 'Rheinland-Pfalz' },
  { id: 'saarland', name: 'Saarland' },
  { id: 'saxony', name: 'Sachsen' },
  { id: 'saxonyAnhalt', name: 'Sachsen-Anhalt' },
  { id: 'schleswigHolstein', name: 'Schleswig-Holstein' },
  { id: 'thuringia', name: 'Thüringen' },
]
