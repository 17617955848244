import { IMaterialShopValues } from '@/types/materialshopvalues'

const axios = require('axios')

export default async function materialShopSubmit(
  values: IMaterialShopValues
): Promise<any> {
  const response = await axios.post(
    process.env.GATSBY_MATERIALSHOP_SERVICE_URL +
      '/api/orderformforministries/orders',
    values
  )
  return response
}
