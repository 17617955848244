import axios from 'axios'
import { IMaterialShopValues } from '@/types/materialshopvalues'

export default async function materialShopPutSubmitWithOrderId(
  token: string,
  orderId: string,
  values: IMaterialShopValues
): Promise<any> {
  const response = await axios.put(
    `${process.env.GATSBY_MATERIALSHOP_SERVICE_URL}/api/orderformforministries/orders/${orderId}`,
    values,
    {
      headers: {
        accept: 'application/json',
        token: token,
      },
    }
  )
  return response
}
