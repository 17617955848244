import React, { ReactElement, useMemo } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Container from '@objects/container'
import Headline from '@objects/headline'
import { FormattedMessage, useIntl } from 'react-intl'
import BreadCrumbs from '@objects/breadcrumb'
import MaterialShopCheckoutForm from '@components/forms/materialshopcheckoutform'
import { $materialShopPaths } from '@services/stores/materialShop'
import { useStore } from '@nanostores/react'

const useStyles = makeStyles((theme) => ({
  headlineWrapper: {
    paddingTop: theme.spacing(12),
    [theme.breakpoints.up('lg')]: {
      paddingTop: theme.spacing(20),
    },
  },
}))

export default function RootIndex(): ReactElement {
  const classes = useStyles()
  const intl = useIntl()
  const materialShopPaths = useStore($materialShopPaths)
  const breadcrumbs = useMemo(() => {
    return [
      {
        label: intl.formatMessage({
          id: 'materialshop.breadcrumb.shop.label',
        }),
        link: materialShopPaths.shop,
      },
      {
        label: intl.formatMessage({
          id: 'materialshop.breadcrumb.cart.label',
        }),
        link: materialShopPaths.cart,
      },
      {
        label: intl.formatMessage({
          id: 'materialshop.breadcrumb.checkout.label',
        }),
      },
    ]
  }, [])

  return (
    <section>
      <Container background={'light'} type="nomargin">
        <BreadCrumbs crumbList={breadcrumbs} />
      </Container>

      <Container className={classes.headlineWrapper} width={'sm'}>
        <Headline level={1}>
          <FormattedMessage id={'materialshop.form.checkoutHeadline'} />
        </Headline>
        <p>
          <FormattedMessage id={'materialshop.form.checkoutSubline'} />
        </p>
      </Container>
      <Container width={'sm'} background={'light'}>
        <MaterialShopCheckoutForm />
      </Container>
    </section>
  )
}
